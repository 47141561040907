import React from "react"

import colors from "../colors"

import { PageLayout } from "../components/layouts"
import SEO from "../components/seo"

import SingleColumnBlock from "../components/singleColumnBlock"

import {
  ContactForm,
  FormWrapper,
  RequestMoreInfo,
  RequestQuote,
} from "@components/Forms"

import {
  RequestMoreInfoButton,
  ContactUsTodayButton,
  DownloadProductBrochureButton,
} from "../components/buttons"

const iconProps = {
  size: "60px",
  color: colors.darkBlue,
}

const Applications = () => {
  return (
    <PageLayout>
      <SEO
        title="Applications | ArtVue Glass"
        ogDescription=""
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h1>
              Applications
            </h1>
            <p>
              
            </p>
          </div>
        }
      />
    </PageLayout>
  )
}

export default Applications
